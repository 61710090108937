<template>
  <router-view />
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { mapGetters } from 'vuex'

export default {
  name: 'MainRoot',
  data() {
    return {
      // isLoading: true,
      // user: {},
    };
  },
  computed: {
    ...mapGetters([
      'loggedMember', 
      'loggedUser',
      // 'communityinitialized',
      // 'mainCollective',
      // 'currentCollective',
      // 'collectiveFavicon'
    ]),
    
    communitySlug() {
      const slug = this.$route.params.communityId;
      // console.log('MainRoot - communitySlug computed - Raw route param:', slug, 'Type:', typeof slug);
      
      // Thanks to our paramNormalizer guard, slug should always be a string
      // Just add fallback for when no slug is provided
      if (!slug) {
        const defaultSlug = process.env.VUE_APP_SPACE_SLUG || 'nectios';
        // console.log('MainRoot - communitySlug computed - No slug, using default:', defaultSlug);
        return defaultSlug;
      }
      
      // Additional safety check for object values
      if (typeof slug === 'object' && slug !== null) {
        // console.warn('MainRoot - communitySlug computed - slug is object:', JSON.stringify(slug));
        const extractedSlug = slug.slug || slug.id || process.env.VUE_APP_SPACE_SLUG || 'nectios';
        // console.log('MainRoot - communitySlug computed - extracted from object:', extractedSlug);
        return extractedSlug;
      }
      
      // console.log('MainRoot - communitySlug computed - using string slug:', String(slug));
      return String(slug);
    },
    // mainSpace() {
    //   return this.currentCollective?.parentKey ? this.mainCollective : this.currentCollective;
    // }
  },

  async created() {
    // console.log('MainRoot created');
    // console.log('MainRoot - Route path:', this.$route.path, 'Full Route:', this.$route.fullPath);
    // console.log('MainRoot - Raw route params:', JSON.stringify(this.$route.params));

    // Handle root path differently
    if (this.$route.path === '/') {
      if (this.loggedMember.id) {
        // console.log('Member already logged in - At root - Redirecting to my page');
        return this.$router.push('/my/dashboard');
      } else {
        // console.log('Member not logged in - At root - Redirecting to login');
        return this.$router.push('/auth/login');
      }
    }

    // Initialize validSlug from route param
    let validSlug = this.communitySlug;
    let dontUseSlug = false;
    
    // console.log('MainRoot - this.communitySlug type:', typeof validSlug);
    
    // Handle invalid/missing slug
    if (!validSlug || validSlug === 'error' || validSlug === 'undefined' || validSlug === 'null') {
      // console.log('MainRoot - Invalid communitySlug:', validSlug);
      validSlug = process.env.VUE_APP_SPACE_SLUG || 'nectios';
      dontUseSlug = true;
    } 
    // Handle object slug
    else if (typeof validSlug === 'object' && validSlug !== null) {
      // If community slug is an object, extract valid slug
      // console.log('MainRoot - communitySlug is object:', validSlug);
      validSlug = validSlug.slug || validSlug.id || process.env.VUE_APP_SPACE_SLUG || 'nectios';
      // console.log('MainRoot - normalized to:', validSlug);
      dontUseSlug = validSlug === 'nectios'; // Only don't use if it's the default
    }
    
    // Ensure it's a string
    validSlug = String(validSlug);
    // console.log('MainRoot - final this.communitySlug', validSlug);

    if(!this.loggedMember.id) {
      // console.log('Member not logged in')
      const isBackoffice = this.$route.path.includes('/backoffice');
      // console.log('MainRoot.vue 2')
      // console.log('dispatch fetchLoggedUser2 this.communitySlug:', validSlug)
      await this.$store.dispatch('fetchLoggedUser2', {
        communitySlug: dontUseSlug ? null : validSlug,
        isBackoffice: isBackoffice
      });
    }

    // this.isLoading = false;
    // console.log('FINAL MainRoot.vue')
  },

  mounted() {
    if (!this.$store.state.copernicData.mountedSocket[this.communitySlug]) {
      // Ensure communitySlug is a string before using in socket
      let safeSlug = this.communitySlug;
      if (typeof safeSlug === 'object' && safeSlug !== null) {
        // console.warn('MainRoot - communitySlug is an object in socket emit:', JSON.stringify(safeSlug));
        safeSlug = safeSlug.slug || safeSlug.id || '';
      }
      safeSlug = String(safeSlug || '');
      // console.log('MainRoot - joining analytics socket with safeSlug:', safeSlug);
      
      this.$socket.emit('joinAnalytics', safeSlug);
      this.$store.state.copernicData.mountedSocket[safeSlug] = true;
    }
    if (!this.$store.state.copernicData.mountedSocket.global) {
      this.notifications();
      this.$store.state.copernicData.mountedSocket.global = true;
    }
  },

  methods: {
    notifications() {
      // 
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },

  },
};
</script>
